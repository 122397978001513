import Carousel from 'react-bootstrap/Carousel';
import "../BootCarousel/bootcarousel.css"
import { CarouselItem } from 'react-bootstrap';
import { Link } from "react-router-dom"

function UncontrolledExample() {

    // const navigate = useNavigate();



    return (
        <div className="carousel">

            <div className="carousel_content">
                <Carousel>
                    <CarouselItem>
                        <Link to="/kategori/:kategoriId/10">
                            <img src={require(`../../assets/images/nil1.jpeg`)} alt="koltuk takımı" />
                        </Link>
                    </CarouselItem>
                    <CarouselItem>
                        <Link to="/kategori/:kategoriId/134">
                            <img src={require(`../../assets/images/viola4.jpg`)} alt="yemek odası" />
                        </Link>
                    </CarouselItem>
                    <CarouselItem>
                        <Link to="/kategori/:kategoriId/72">
                            <img src={require(`../../assets/images/viola1.jpg`)} alt="tv ünitesi" />
                        </Link>
                    </CarouselItem>
                    <CarouselItem>
                        <Link to="/kategori/:kategoriId/85">
                            <img src={require(`../../assets/images/viola1yatak.JPG`)} alt="yatak odası" />
                        </Link>
                    </CarouselItem>
                </Carousel>
            </div>

        </div >
    );
}

export default UncontrolledExample;


















