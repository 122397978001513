import React from 'react'
import "../ProductDetails/productdetails.css"
import Data from "../Data.json"
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import ModalImage from 'react-modal-image'






const ProductDetails = () => {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { productId } = useParams()
    const urun = Data.navigasyon
        .flatMap(category => category.alt_kategoriler)
        .find(product => product.id === productId)

    console.log(urun);

    const kategoripath = Data.navigasyon.find(category => category.kategori === urun.kat).kategoripath;

    const [currentImage, setCurrentImage] = useState(urun.resim);

    const handleClick = (urunResim) => {
        setCurrentImage(urunResim);
        window.scrollTo(0, 0)
    };

    const mainImage = require(`../../assets/images/${kategoripath}/${urun.urunKlasorIsim}/${currentImage}`)



    console.log(mainImage);
    return (
        <div className="detail-main">
            <div className="detail-top">
                <div className="detail-main-image">
                    <ModalImage className='main-image' small={mainImage} large={mainImage} style={{
                        width: '100%',
                        objectFit: 'cover'
                    }} alt='' />
                </div>
                <div className="detail-info">
                    <div className="detail-info-top">
                        <span>Model / </span>
                        <span>{urun.marka}</span>
                    </div>
                    <span>Kategori / </span>
                    <span>{urun.kat}</span>
                    <p>{urun.urunIsim}</p>
                    <button onClick={() => navigate(-1)} className='turnback'>GERİ DÖN</button>
                </div>
            </div>
            <div className="detail-bottom">
                {
                    urun.resimler.map((urunResim, i) => {
                        return (
                            <div className="detail-images-box">
                                <img className='bottom-images' src={require(`../../assets/images/${kategoripath}/${urun.urunKlasorIsim}/${urunResim}`)} alt="" onClick={() => handleClick(urunResim)} />
                            </div>
                        )
                    })
                }

            </div>

            <Link to='/' ><button>ANA SAYFAYA DÖN</button></Link>
        </div>
    )
}

export default ProductDetails


