import React from 'react'
import "../Products/products.css"
import Data from "../Data.json"
import { useParams, Link } from 'react-router-dom'
import { useEffect } from 'react'



const Products = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { kategoriId } = useParams()


    const secilenUrun = Data.navigasyon.find((secilen) => secilen.id === kategoriId)
    console.log(secilenUrun);

    const urun = secilenUrun.alt_kategoriler.map((urun) => urun).map((urun) => urun)


    return (
        <>
            <nav className='nav'>

                {
                    Data.navigasyon.map((kategori) => {
                        return (

                            <>
                                <Link to={`/kategori/${kategori.id}`} >
                                    <li>{kategori.kategori}</li>
                                </Link>

                            </>

                        )
                    })
                }
            </nav>



            <div className='products' key={secilenUrun.id}>
                {
                    urun.map((urun) => {
                        return (


                            <Link to={`/kategori/:kategoriId/${urun.id}`}>

                                <div className="products__content">
                                    <div className="products__content_imageBox">
                                        <img src={require(`../../assets/images/${secilenUrun.kategoripath}/${urun.urunKlasorIsim}/${urun.resim}`)} alt="" />
                                    </div>
                                    <p className='model'>{urun.marka}</p>
                                    <p>{urun.urunIsim}</p>
                                    <p>{urun.fiyat}</p>

                                </div>



                            </Link>
                        )
                    })}



            </div>


        </>

    )
}

export default Products
