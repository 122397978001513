import React, { useState } from 'react'
import "../Navigation/navigation.css"
import { Link } from "react-router-dom"
import Data from "../Data.json"

import { FaInstagram, FaFacebookSquare } from "react-icons/fa"
import { SlMenu } from "react-icons/sl";
import { GoX } from "react-icons/go";








const Navigation = () => {

    const [toggleMenu, setToggleMenu] = useState(false)






    return (
        <div className='navigation'>

            <div className="navigation_logo">
                <Link to="/" ><img src={require('../../assets/images/logo.jpg')} alt="" /></Link>
            </div>




            <div className="navigation_menu">
                {Data.navigasyon.map((kategoriAdi) => {
                    return (
                        <Link to={`/kategori/${kategoriAdi.id}`} >
                            <div className="prodBox" key={kategoriAdi.id} >
                                <div className="line"></div>
                                <li>{kategoriAdi.kategori}</li>
                                <div className="line"></div>
                            </div>
                        </Link>
                    )
                })}
            </div>


            <div className="navigation_social">
                <li><a href="https://www.instagram.com/rennhomefurniture/"><FaInstagram /></a></li>
                <li><FaFacebookSquare /></li>

            </div>

            <div className="navigation_burger">
                <li >
                    <SlMenu className='menu_line' onClick={() => setToggleMenu(true)} />


                    {toggleMenu && (
                        <div className="navigation_menu_smallscreen_overlay">
                            <GoX className='overlay__close' onClick={() => setToggleMenu(false)} />
                            <div className="navigation_menu_smallscreen">
                                {Data.navigasyon.map((kategoriAdi) => {
                                    return (
                                        <Link to={`/kategori/${kategoriAdi.id}`}><li onClick={() => setToggleMenu(false)}>{kategoriAdi.kategori}</li> </Link>
                                    )
                                })}
                            </div>
                        </div>
                    )
                    }


                </li>

            </div>




        </div>
    )
}

export default Navigation
