import React from 'react'
import UncontrolledExample from "../../components/BootCarousel/BootCarousel"
import Features from "../../components/Features/Features"
import Sets from "../../components/Sets/Sets"



import "./header.css"



const Header = () => {
    return (
        <>
            <div className='header'>
                <div className="header_sliderBox">
                    <UncontrolledExample />
                </div>
            </div>
            <Features />
            <Sets />

        </>

    )
}

export default Header
