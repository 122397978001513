import React from 'react'
import "../Features/features.css"
import Quarantee from "../../assets/images/guarantee.png"
import Cargo from "../../assets/images/cargo.png"
import Security from "../../assets/images/security.png"
import Services from "../../assets/images/services.png"

const Features = () => {
    return (
        <div className='features'>
            <div className="feature_box ">
                <div className="fb_item quality">
                    <img src={Quarantee} alt="" />
                </div>
                <h3>YÜKSEK KALİTE</h3>
            </div>
            <div className="feature_box ">
                <div className="fb_item cargo">
                    <img src={Cargo} alt="" />
                </div>
                <h3>hızlı kargo</h3>
            </div>
            <div className="feature_box ">
                <div className="fb_item security">
                    <img src={Security} alt="" />
                </div>
                <h3>yüksek güvenlik</h3>
            </div>
            <div className="feature_box ">
                <div className="fb_item service">
                    <img src={Services} alt="" />
                </div>
                <h3>Müşteri Servisi</h3>
            </div>
        </div>
    )
}

export default Features
