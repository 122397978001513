import React from 'react'
import "./footer.css"
import { Link } from "react-router-dom"
import { FaInstagram, FaFacebookSquare } from "react-icons/fa"

const Footer = () => {
    return (
        <div className='footer'>
            <div className="footer_left">
                <div className="contact">
                    <h4>İletişim</h4>
                    <p>Tel : 0532 404 26 16</p>
                </div>
                <div className="adress">
                    <h4>Adres :</h4>
                    <p>Mahmudiye mahallesi. Sarmaşık sokak. No: 6  <br /> İnegöl / Bursa</p>
                </div>
            </div>
            <div className="footer_logo">
                <Link to="/" ><img src={require("../../assets/images/logo.jpg")} alt="" /></Link>
            </div>
            <div className="footer_right">
                <p>Bizi Takip Edin</p>
                <div className="social">
                    <li><a href="https://www.instagram.com/rennhomefurniture/"><FaInstagram /></a></li>
                    <li><FaFacebookSquare /></li>
                </div>

            </div>

        </div>
    )
}

export default Footer
