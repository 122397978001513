import './App.css';
import { Products, ProductDetails, Header, Footer, Navigation } from "./pages/index"
import { Route, Routes } from "react-router-dom";
import { Link } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Routes>
        <Route path="/" element={<Header />} />
        <Route path="/kategori/:kategoriId" element={<Products />} />
        <Route path="/kategori/:kategoriId/:productId" element={<ProductDetails />} />
        <Route path="/kategori/:kategoriIdd/:urunId" element={<ProductDetails />} />
        <Route path="/kategori/:slideKategoriId/:kategori" element={<ProductDetails />} />
      </Routes>
      <Footer />
      <div className="wp">
        <Link to="https://wa.me/+905324042616">
          <h4>Bize Ulaşın</h4>
          <li><IoLogoWhatsapp /></li>
        </Link>
      </div>
    </div>
  );
}

export default App;
