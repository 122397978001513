import React from 'react'
import "../Sets/sets.css"
import { Link } from "react-router-dom"
import { useEffect } from 'react'
import Data from "../../pages/Data.json"




const Sets = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="sets">
            {
                Data.navigasyon.map((kategoriAdi) => {
                    return (
                        <>
                            <h3 key={kategoriAdi.id}>{kategoriAdi.kategori}</h3>
                            <div className="sets_content">
                                {
                                    Data.navigasyon.map((urun) => urun).map((urun) => urun.alt_kategoriler.map((urun) => {
                                        if (urun.kat === kategoriAdi.kategori) {
                                            return (

                                                <Link to={`/kategori/${urun.marka}/${urun.id}`} key={urun.id}>
                                                    <div className="setBoxes" >
                                                        <div className="setBox_image">
                                                            <img src={require(`../../assets/images/${kategoriAdi.kategoripath}/${urun.urunKlasorIsim}/${urun.resim}`)} alt="" />
                                                        </div>
                                                        <h4>{urun.marka}</h4>
                                                        <h3>{urun.urunIsim}</h3>
                                                    </div>
                                                </Link>
                                            )
                                        }
                                    }))
                                }
                            </div>
                        </>
                    )
                })
            }


        </div>
    )
}







export default Sets
